import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  Modal,
  Box,
  Backdrop,
  Fade,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useViewport } from '../../utils/useViewport';
import { actionUpdateUserRequest } from '../../store/User/UserAction';
import NativeSelector from '../../components/core/NativeSelector';
import Typography from '../../components/overrides/Typography';
import CloseSvg from '../../components/svg/Close';
import gravityPalette from '../../components/gravity/global/gravityPalette';
import { actionGetMarketRequest } from '../../store/Markets/MarketsAction';
import {
  ONBOARDING_LOCATION_TITLE,
  ONBOARDING_LOCATION_INPUT_LABEL,
  ONBOARDING_LOCATION_TOOLTIP_LABEL,
  CLOSE_BUTTON_ALT_TEXT,
  SELECT_BUTTON_LABEL,
  ONBOARDING_LITE_LOCATION_TOOLTIP_TEXT,
  EXPLORE_RESTAURANTS_BUTTON_TEXT,
} from '../../assets/copy';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import ProgressButton from './ProgressButton';
import { Layout, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from './Layout';
import OnboardingLocationBg from '../../assets/images/napasonoma-day1-232.jpeg';
import {
  trackClickWithDescription,
  trackPageView,
} from '../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  label: {
    margin: theme.spacing(1, 0),
    color: theme.palette.beacon.midnight,
  },
  card: {
    minHeight: 140,
    color: theme.palette.beacon.midnight,
    backgroundColor: 'transparent',
    boxShadow: `none`,
    borderRadius: 4,
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      width: 344,
    },
  },
  cardContent: {
    padding: '0px !important',
  },
  description: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      paddingBottom: 0,
    },
  },
  noCityButton: {
    background: 'transparent',
    border: 'none',
    color: theme.palette.beacon.midnight,
    display: 'block',
    font: 'inherit',
    padding: 0,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      display: 'inline',
    },
  },
  close: {
    position: 'absolute',
    right: 8,
    top: 8,
    width: '14px',
    height: '14px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 327,
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      height: 'auto',
      width: 480,
      margin: 'auto',
    },
    boxSizing: 'border-box',
    padding: theme.spacing(4, 3),
    backgroundColor: theme.palette.digitalGray[10],
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  closeButtonContainer: {
    transform: 'translateY(-10px)',
  },
  tooltipTitle: {
    fontWeight: 300,
    margin: theme.spacing(0.5, 0, 3),
  },
  title: {
    color: theme.palette.beacon.midnight,
    fontSize: '32px',
    lineHeight: '42px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      marginTop: theme.spacing(2.5),
    },
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      marginBottom: theme.spacing(4.5),
    },
  },
  divider: {
    '&.MuiDivider-root': {
      backgroundColor: theme.palette.beacon.heritageRed,
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        backgroundColor: theme.palette.beacon.brick,
      },
    },
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      marginRight: theme.spacing(8),
    },
  },
  button: {
    backgroundColor: theme.palette.beacon.atlantic,
    width: 'max-content',
    height: 'min-content',
    padding: theme.spacing(1, 3.5, 1.25),
    borderRadius: 6,
    '& .MuiButton-label p': {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  buttonContainer: {
    flexGrow: 1,
    margin: theme.spacing(2, 0, 1.5),
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      flexGrow: 0,
      margin: theme.spacing(4, 0, 1.5),
    },
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      margin: theme.spacing(5, 0, 1.5),
    },
  },
  expandingContent: {
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  nativeSelector: {
    '&.MuiSelect-root:focus-visible': {
      outlineColor: theme.palette.primary.main,
    },
  },
  selectorForm: {
    maxWidth: 320,
  },
}));

const Location = ({ handleNext, list }) => {
  const { width } = useViewport();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [marketId, setMarketId] = useState('');
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const isButtonDisabled = useMemo(
    () => marketId === '' || marketId === '0',
    [marketId]
  );
  const isBusy = useMemo(() => user.isRequestPending, [user.isRequestPending]);

  const handleSelectLocation = (event) => {
    setMarketId(event.target.value);
  };

  const handleDispatchLocation = () => {
    trackClickWithDescription(
      'onboarding-explore-restaurants-cta',
      'Button',
      marketId
    );
    dispatch(actionUpdateUserRequest({ id: user.id, marketId }));
    dispatch(actionGetMarketRequest({ id: marketId }));
  };

  const handleOpen = () => {
    trackClickWithDescription('onboarding-tooltip-no-city', 'Button');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    trackPageView('/onboarding__location');
  }, []);

  // wait for the user to be updated before moving to the next step
  useEffect(
    () => marketId && user?.market && handleNext(),
    [user, marketId, handleNext]
  );

  return (
    <>
      <Layout backgroundImage={OnboardingLocationBg}>
        <Box className={classes.wrapper}>
          <Box className={classes.expandingContent}>
            <Typography
              className={classes.title}
              component="h1"
              variant="large1LightFlex">
              {ONBOARDING_LOCATION_TITLE}
            </Typography>
            <Divider className={classes.divider} />

            <Card className={classes.card}>
              <Typography
                color="secondary"
                variant="small2Normal"
                aria-hidden="true"
                className={classes.label}>
                {ONBOARDING_LOCATION_INPUT_LABEL}
              </Typography>
              <CardContent className={classes.cardContent}>
                <NativeSelector
                  color="secondary"
                  ariaLabel={ONBOARDING_LOCATION_INPUT_LABEL}
                  placeholder={SELECT_BUTTON_LABEL}
                  role="combobox"
                  items={list}
                  handleSelect={handleSelectLocation}
                  selectedItem={marketId}
                  classes={{
                    inputSelect: classes.nativeSelector,
                    formControl: classes.selectorForm,
                  }}
                />
                <Typography
                  color="secondary"
                  variant="small3Normal"
                  className={classes.description}>
                  <button className={classes.noCityButton} onClick={handleOpen}>
                    {ONBOARDING_LOCATION_TOOLTIP_LABEL}
                  </button>
                </Typography>
              </CardContent>
              <Box className={classes.buttonContainer}>
                <ProgressButton
                  variant="small2Semibold"
                  isBusy={isBusy}
                  onClick={handleDispatchLocation}
                  disabled={isButtonDisabled}
                  text={EXPLORE_RESTAURANTS_BUTTON_TEXT}
                  classesOverride={{ button: classes.button }}
                />
              </Box>
            </Card>
          </Box>
        </Box>
      </Layout>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        role="dialog"
        aria-label={ONBOARDING_LOCATION_TOOLTIP_LABEL}
        aria-describedby="dialoglabel dialogdescription"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <Box className={classes.container}>
            <Box
              display={'flex'}
              justifyContent={'flex-end'}
              className={classes.closeButtonContainer}>
              <Box zIndex={2}>
                <CloseSvg
                  className={classes.close}
                  height={width < WIDTH_BREAKPOINT ? 14 : 20}
                  width={width < WIDTH_BREAKPOINT ? 14 : 20}
                  cursor="pointer"
                  fill={gravityPalette.button.progressive.default}
                  onClick={handleClose}
                  onKeyPress={(e) => e.key === 'Enter' && handleClose()}
                  role="button"
                  tabIndex={0}
                  aria-label={CLOSE_BUTTON_ALT_TEXT}
                />
              </Box>
            </Box>
            <Box textAlign={'left'} id="dialog-title">
              <Typography
                variant="medium3Light"
                className={classes.tooltipTitle}
                component="h2"
                id="dialoglabel">
                {ONBOARDING_LOCATION_TOOLTIP_LABEL}
              </Typography>
            </Box>
            <Box textAlign={'left'} id="inactivity-text">
              <Typography
                variant="medium1Normal"
                component="span"
                id="dialogdescription">
                {ONBOARDING_LITE_LOCATION_TOOLTIP_TEXT}
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default Location;
